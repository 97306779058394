import React from 'react';

import '../components/style.scss';
import Hero from '../components/hero';
import Helmet from '../components/helmet';

const Index = () => {
  return (
    <div>
      <Helmet />
      <Hero />
    </div>
  );
};

export default Index;
